import { __decorate } from "tslib";
/* eslint-disable */
// tslint:disable
import { Vue, Component } from 'vue-property-decorator';
import DocumentEditorComponent from '@/components/DocumentEditorComponent.vue';
import { contratoStore, alunoStore, cityStore, companyConfiguracaoStore, mainStore, } from '@/utils/store-accessor';
import { newAluno } from '@/interfaces/aluno';
let Contrato = class Contrato extends Vue {
    constructor() {
        super(...arguments);
        this.alunoContratoId = 0;
        this.alunoData = { id: 0, ...newAluno() };
        this.alunoId = null;
        this.city = {};
        this.content = [];
        this.contratoData = {};
    }
    async created() {
        if (this.$route.query.alunoId) {
            this.alunoId = +this.$route.query.alunoId;
            this.alunoData = await alunoStore.getAluno(this.alunoId);
        }
        if (this.$route.query.contratoId) {
            this.alunoContratoId = +this.$route.query.contratoId;
            this.contratoData = await contratoStore.getContrato(this.alunoContratoId);
        }
        await companyConfiguracaoStore.getCompanyConfiguracoes();
        this.city = await cityStore.getCityByCodigoMunicipio(this.userProfile.company.city_codigo_municipio_completo);
        // @ts-ignore
        if (this.configuracoes.contrato) {
            // @ts-ignore
            this.content = this.configuracoes.contrato;
            return;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get configuracoes() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
};
Contrato = __decorate([
    Component({
        components: {
            DocumentEditorComponent,
        },
    })
], Contrato);
export default Contrato;
