export function newAluno() {
    return {
        aluno_bairro: '',
        aluno_celular: '',
        aluno_cep: '',
        aluno_codigo_municipio_completo: 0,
        aluno_complemento: '',
        aluno_cpf: '',
        aluno_data_nascimento: '',
        aluno_email: '',
        aluno_nome: '',
        aluno_numero: '',
        aluno_ocupacao: '',
        aluno_orgao_emissor: '',
        aluno_responsavel_financeiro: true,
        aluno_rg: '',
        aluno_rne: '',
        aluno_rua: '',
        aluno_sexo: '',
        responsavel_celular: null,
        responsavel_cpf: null,
        responsavel_data_nascimento: null,
        responsavel_email: null,
        responsavel_nome: null,
        responsavel_ocupacao: null,
        responsavel_orgao_emissor: null,
        responsavel_pedagogico_nome: null,
        responsavel_pedagogico_parentesco: null,
        responsavel_pedagogico_telefone: null,
        responsavel_rg: null,
        responsavel_rne: null,
        responsavel_sexo: null,
    };
}
